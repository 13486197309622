import ThreeBox from './stories/ThreeBox.tsx'

function App() {
  return (
    <>
      <ThreeBox />
    </>
  )
}

export default App
